import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { useTranslation } from "react-i18next";

import ColorInternalButton from "../../Global/Buttons/ColorInternalButton";
import { GatsbyImage } from "gatsby-plugin-image";

const ProductCard = ({ product, destination }) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col gap-2'>
      <Link to={`/products/${destination}`}>
        <div className='flex flex-col gap-2'>
          <div className='bg-neutral-200'>
            <GatsbyImage
              image={product.frontmatter.thumb.childImageSharp.gatsbyImageData}
              alt={product.frontmatter.title}
              className='hover:opacity-50 duration-300'
            />
          </div>
          <h3 className='sm:order-none sm:font-normal sm:text-center order-first h-8 text-sm font-bold text-left'>
            {product.frontmatter.title}
          </h3>
        </div>
      </Link>

      <div className='self-center'>
        <ColorInternalButton
          label={t("TR_PRODUCTS_BUTTON_SEE_MORE")}
          destination={`/products/${destination}`}
        />
      </div>
      <hr className='sm:hidden border-neutral-400 mt-5' />
    </div>
  );
};

export default ProductCard;
