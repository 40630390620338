import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import PageHeader from "../components/Global/PageHeader";
import ProductCard from "../components/Products/ProductCategory/ProductCard";
import ProductFormMock from "../components/Products/ProductForm/ProductFormMock";
import Layout from "../components/Layout";

const Products = (props) => {
  const { t } = useTranslation();

  const productInfo = props.data.allMarkdownRemark.nodes;
  const data = {
    pageTitle: t("TR_PRODUCTS_PAGE_TITLE"),
    description: t("TR_PRODUCTS_PAGE_DESCRIPTION"),
  };

  return (
    <div className='bg-neutral-50 text-neutral-700'>
      <Layout>
        {data && (
          <>
            <PageHeader pageTitle={data.pageTitle} backButton={false} />
            <div className='flex justify-center'>
              <h2 className='w-2/3 text-sm text-center'>{data.description}</h2>
            </div>
          </>
        )}
        {productInfo && (
          <div className='sm:gap-10 md:gap-14 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 py-7 sm:py-10 sm:px-20 grid grid-cols-1 gap-5 px-10'>
            {productInfo.map((product) => (
              <div key={product.id}>
                <ProductCard
                  product={product}
                  destination={product.frontmatter.slug}
                />
              </div>
            ))}
          </div>
        )}
        <ProductFormMock />
      </Layout>
    </div>
  );
};

export default Products;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/products/i" }
        frontmatter: { language: { eq: $language } }
      }
      sort: { fields: frontmatter___listOrder, order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
          thumb {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export const Head = ({ data }) => {
  const title = JSON.parse(data.locales.edges[0].node.data).TR_MENU_PRODUCTS;

  return (
    <>
      <title>{title} | CADT</title>
    </>
  );
};
