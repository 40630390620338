import React from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import ArrowLeft from "../../Icons/ArrowLeft";

const BackButton = () => {
  const { t } = useTranslation();

  return (
    <button
      onClick={() => typeof window !== "undefined" && window.history.back()}
    >
      <div className='top-24 md:top-14 gray-button absolute left-0 flex gap-2 px-10 py-2 text-xs font-bold text-white'>
        <ArrowLeft />
        {t("TR_BUTTON_BACK")}
      </div>
    </button>
  );
};

export default BackButton;
