import React from "react";

const ProductFormMock = () => {
  return (
    <div className='hidden'>
      <form
        name='products'
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        encType='multipart/form-data'
      >
        <input type='hidden' name='form-name' value='products' />
        <input type='hidden' name='subject' value='' />
        <input type='hidden' name='bot-field' />
        <input type='text' name='topic' />
        <input type='text' name='name' />
        <input type='text' name='company' />
        <input type='email' name='email' />
        <textarea type='text' name='message'></textarea>
        <input type='checkbox' name='privacy' />
      </form>
    </div>
  );
};

export default ProductFormMock;
