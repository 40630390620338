import React from "react";
import BackButton from "../Global/Buttons/BackButton";

const PageHeader = ({ pageTitle, backButton = true }) => {
  return (
    <div className=' md:pt-20 pt-36 relative flex justify-center px-5 mb-1'>
      {backButton && <BackButton />}
      {pageTitle && (
        <h2 className='lg:text-4xl text-neutral-700 pt-4 text-3xl font-bold text-center'>
          {pageTitle}
        </h2>
      )}
    </div>
  );
};

export default PageHeader;
