import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

const ColorInternalButton = ({ label, destination, color }) => {
  const bgColor = color ? `bg-${color}` : "bg-cadt";
  const hoverColor = color ? `hover:bg-${color}-hover` : "hover:bg-cadt-hover";

  return (
    <Link to={destination}>
      <button className={`${bgColor} ${hoverColor} rounded-button`}>
        {label}
      </button>
    </Link>
  );
};

export default ColorInternalButton;
